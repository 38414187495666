import React from 'react'

function Logo (props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 320 283.1"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st3{fill:#fff}"}</style>
      <g fillRule="evenodd" clipRule="evenodd">
        <circle cx={220.7} cy={105.8} r={99.3} fill="#fad74b" />
        <circle cx={220.6} cy={105.7} r={79.6} fill="#fe9431" />
        <circle cx={220.6} cy={105.7} r={60.5} fill="#e3222a" />
      </g>
      <path
        className="prefix__st3"
        d="M37.4 255.4H14.7c-.5 0-.9.4-.9.9v5.2c0 .5.4.9.9.9h7.1c.4 0 .7.3.7.7v19.3c0 .5.4.9.9.9h5.4c.5 0 .9-.4.9-.9V263c0-.4.3-.7.7-.7h7.1c.5 0 .9-.4.9-.9v-5.2c-.2-.4-.6-.8-1-.8zM71.3 256.2c-.2-.5-.7-.8-1.2-.8h-5.3c-.5 0-1 .3-1.2.8L53 282.4c-.1.4.1.8.5.8h6c.5 0 1-.3 1.1-.8l1-2.6c.1-.4.5-.7 1-.7h9.5c.4 0 .8.3 1 .7l1 2.6c.2.5.6.8 1.1.8h6c.4 0 .7-.4.5-.8l-10.4-26.2zm-1.5 16.5h-4.7c-.4 0-.6-.4-.5-.7l2.6-6.8c.1-.2.4-.2.5 0l2.5 6.8c.1.3-.1.7-.4.7zM120.2 255.4H97.5c-.5 0-.9.4-.9.9v5.2c0 .5.4.9.9.9h7.1c.4 0 .7.3.7.7v19.3c0 .5.4.9.9.9h5.4c.5 0 .9-.4.9-.9V263c0-.4.3-.7.7-.7h7.1c.5 0 .9-.4.9-.9v-5.2c-.2-.4-.6-.8-1-.8zM154.1 256.2c-.2-.5-.7-.8-1.2-.8h-5.3c-.5 0-1 .3-1.2.8l-10.6 26.2c-.1.4.1.8.5.8h6c.5 0 1-.3 1.1-.8l1-2.6c.1-.4.5-.7 1-.7h9.5c.4 0 .8.3 1 .7l1 2.6c.2.5.6.8 1.1.8h6c.4 0 .7-.4.5-.8l-10.4-26.2zm-1.6 16.5h-4.7c-.4 0-.6-.4-.5-.7l2.6-6.8c.1-.2.4-.2.5 0l2.5 6.8c.2.3 0 .7-.4.7zM210 255.4h-5.4c-.5 0-.9.4-.9.9v14c0 .2-.3.4-.4.1L192 256.1c-.3-.4-.9-.7-1.4-.7h-4.3c-.5 0-.9.4-.9.9v26.1c0 .5.4.9.9.9h5.5c.5 0 .9-.4.9-.9v-13.9c0-.2.3-.4.4-.1l11.4 14.2c.3.4.9.7 1.4.7h4.3c.5 0 .9-.4.9-.9v-26.1c-.2-.5-.6-.9-1.1-.9zM247.8 267.9c-.2-.3-.2-.8.1-1.1l9.8-10.7c.2-.2 0-.6-.3-.6H250c-.5 0-1.1.2-1.4.6l-7.5 8.4c-.1.2-.5.1-.5-.2v-8c0-.5-.4-.9-.9-.9h-5.4c-.5 0-.9.4-.9.9v26.1c0 .5.4.9.9.9h5.4c.5 0 .9-.4.9-.9v-6.8c0-.4.2-.9.5-1.2l1-1c.2-.3.7-.2.9 0l6.7 9.2c.3.4.8.7 1.3.7h7.1c.4 0 .6-.4.4-.7l-10.7-14.7zM306.1 282.4l-10.6-26.2c-.2-.5-.7-.8-1.2-.8H289c-.5 0-1 .3-1.2.8l-10.6 26.2c-.1.4.1.8.5.8h6c.5 0 1-.3 1.1-.8l1-2.6c.1-.4.5-.7 1-.7h9.5c.4 0 .8.3 1 .7l1 2.6c.2.5.6.8 1.1.8h6c.6-.1.8-.5.7-.8zm-12.1-9.7h-4.7c-.4 0-.6-.4-.5-.7l2.6-6.8c.1-.2.4-.2.5 0l2.5 6.8c.2.3-.1.7-.4.7zM106.5 226.7l-18.9-16.8c-.5-.4-.9-.8-1.3-1.1l-13.9-12.5H54.8l-6.5 7.4L71 223.9c1.1 2.1 1.8 5.1.8 9.4h40.1c.2-.5.2-.6.4-1.1h.3l-.2-.2c-2-1.8-3.9-3.6-5.9-5.3z"
      />
      <path
        className="prefix__st3"
        d="M263.3 128.3c-2.6-4-2.4-5.5 2.4-7.1 4.9-1.6 10-2.9 15-3.4 5.6-.6 11.3-.1 16.9-.1h6.2c.1-.4.1-.9.2-1.3-8-5.7-16.7-10.3-26.1-12.9-8.4-2.3-17.2-2.4-25.7-.2-4 1-6.2-.1-8.4-3.5-5.3-8.1-11.1-15.8-16.6-23.6-5.3-7.6-12.8-10.5-21.8-10.4-7.2 0-14.1 1.9-20.3 5.1-3.2 1.7-6.4 3.3-9.7 5L99.5 0 80.7 9.9l75.9 75.9c-.6.3-1.1.6-1.7.9L87.5 19.4l-18.9 9.9 67.9 67.9c-.6.4-1.2.7-1.8 1.1L83.2 46.7l-18.9 9.9 52.6 52.6c-.6.4-1.3.8-1.9 1.2L89.2 84.7l-18.9 9.9L97 121.3c-1 .5-2 .9-3.1 1-8.6.5-16.5 2.9-24.1 6.9-.1 0-.2.1-.3.1-.3 0-.8-.1-1.5-.1h-.2-.1c-7-.3-30.6.7-56.8 26.2-3.7 1.3-11 4.6-10 9.6 0 0 7.1 1.9 11.3-8.3 12.4-7.5 39-22.6 46.8-19.3-4.7 5.3-10.3 9.8-12.5 17 1 .5 1.9.9 2.8 1.2 1.1.4 2.4.5 3.5.9 2.9 1 3.2 2.9.8 4.8-2.9 2.4-6.4 2.7-9.7 1.9-3.2-.8-5.4-.2-7.6 2-4.2 4.3-8.4 8.5-12.7 12.7C16 185.8 8 193.6 0 201.4c2.5 3.1 5.3 2.9 8.4 2.3 2.3-.5 4.5.1 5 3 .5 2.6-1.4 4.9-4.5 5.1-2.4.2-4.8 0-8.3 0 2.5 3 4.1 5.3 6 7.3 3.3 3.5 7.1 6.6 10.1 10.3 3.9 4.9 8.3 7.8 14.9 6.9 3-.4 6-.1 9.1-.1h23.5c.3-.6.3-.7.6-1.3-5.9-7-11.9-14.1-17.8-21.1-5.4-6.4-5.4-8.4 0-15.1.9-1.1 1.7-2.3 2.7-3.3 3.4-3.4 7.2-5.4 12.4-4.4 4.1.8 7.1 1.7 11.4 2l33.7 10.1c.5.2 1.1.3 1.6.5 5.5 1.4 8.6-.9 13-3.2 3.5-1.8 7.5-1.5 10.9.9 2.5 1.8 3.7 4.2 2.8 7.4-.9 3-3.2 3.2-5.8 3.2-2.5 0-4.9.2-8.3.3 1.5 2.2 2.1 3.4 3 4.4 5 5.5 10.1 10.9 14.9 16.5 1.9 2.3 4 3 6.8 3 12.3-.1 24.6 0 36.8 0 1.3 0 2.6-.4 4.2-.6-.4-1.2-.6-2-1-2.6-3.3-5-6.6-10.1-10.1-14.9-1.8-2.5-1.6-4.4.6-6.2.9-.8 1.9-1.5 2.8-2.3 9.7-8.4 19.3-16.8 28.9-25.3l4.7 2.1c-8.2 7.4-16.5 14.6-24.7 22.1-3.1 2.8-2.9 3.5-.2 6.4 2.9 3.1 4 3.4 7.1.9 2.4-1.9 4.5-4 6.8-6 4.4-4 8.9-8.1 13.7-12.4 3.2 3 6.1 5.7 9.1 8.4 8-6.6 16.4-13.2 21.4-23 .5-1 2.9-2 4-1.7 3.8 1 5.6-1.2 7.4-3.7 5.6-7.8 11.4-15.6 16.9-23.4 3.2-4.5 3-4.9-.2-9.6-3.8-5.4-7.4-10.7-11-16zm-11.1-8.4c-4.3 4.2-6.6 8.9-7.3 14.7-.5 4.4-1.1 4.9-5 4.9-4.1 0-7.2-2.2-7.4-5.5-.1-1.9.2-4 1-5.8 4.7-11 13.6-16.3 25.1-17.6 2.7-.3 5.6 0 8.3 0-4.8 3.4-10.3 5.1-14.7 9.3z"
      />
      <path
        className="prefix__st3"
        d="M244.2 233.3c.2-.6.3-.6.5-1.2-1.9-1.8-3.8-3.6-5.8-5.4L220 209.9c-3.9-3.5-4.5-3.6-8.6-.2-3.9 3.2-7.8 6.4-11.6 9.7-.8.7-1.1 1.8-1.7 2.8 7.4 3.1 8.4.9 6 11.2h40.1z"
      />
    </svg>
  )
}

export default Logo